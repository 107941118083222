html.dark {
  /* background-color: rgb(15, 23, 42);*/
  background-color: rgb(0, 14, 36);
}

.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(41 98 255 / var(--tw-bg-opacity));
}

.border-blue-500 {
  --tw-border-opacity: 1;
  border-color: rgb(50 100 255 / var(--tw-border-opacity));
}

.hover\:bg-blue-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(50 100 255 / var(--tw-bg-opacity));
}

.hover\:bg-blue-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(50 100 255 / var(--tw-bg-opacity));
}

.dark .dark\:bg-orange-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 204 0 / var(--tw-bg-opacity));
}

.dark .dark\:bg-orange-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(239 190 0 / var(--tw-bg-opacity));
}

.dark .dark\:border-orange-700 {
  --tw-border-opacity: 1;
  border-color: rgb(240 200 0 / var(--tw-border-opacity));
}

.bg-slate-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.footertext {
  font-size: 0.5em;
  text-align: center;
}

.dark p.footertext {
  color: white;
}
